export default function Home() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          <h1>Who Am I?</h1>
          <p>
            I'm a graduate of Johns Hopkins University with a degree in
            both Computer Science and Computer Engineering with a minor in
            Entrepreneurship and Management. I currently work at Traitify
            as a software developer, building interactive web-apps.
          </p>
          <p>
            I'm a human being. Definitely not a robot.
          </p>
        </div>
        <div className="col-md-4">
          <h1>What Can I Do?</h1>
          <p>
            I can create and help you manage your online presence. From a
            website featuring a simple landing page all the way to a complex
            blog or podcast where you also sell products.
          </p>
          <p>
            I can make it so that you manage whatever content you want to
            on your website in a simple, intuitive way. If you ever have a
            problem, you can just give me a call!
          </p>
        </div>
        <div className="col-md-4">
          <h1>Right Now?</h1>
          <p>
            I'm always willing to build something, especially if it will
            positively benefit society.
          </p>
          <p>
            Did I really need a third column here? Probably not, but Tomify
            all is about consistency.
          </p>
        </div>
      </div>
    </div>
  );
}
