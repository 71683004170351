import PropTypes from "prop-types";
import {Component} from "react";
import {Redirect} from "react-router-dom";
import {fetchCurrent as fetchProfile} from "application/requests/profile";
import {create} from "application/requests/session";
import {Alert} from "components/helpers";

export default class Session extends Component {
  static defaultProps = {authenticated: false}
  static propTypes = {
    authenticate: PropTypes.func.isRequired,
    authenticated: PropTypes.bool,
    updateProfile: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props);

    this.state = {user: {}};
  }
  componentDidMount() {
    fetchProfile().then((data) => (this.props.authenticate(data.user != null)));
  }
  clearError = () => {
    this.setState({error: null});
  }
  onChange = (e) => {
    const {name, value} = e.target;

    this.setState((prevState) => (
      {user: {...prevState.user, [name]: value}}
    ));
  }
  onSubmit = (e) => {
    e.preventDefault();

    create({user: this.state.user}).then((data) => {
      if(data.success) {
        this.props.authenticate(data.success);
        this.props.updateProfile(data.user);
      } else {
        this.setState({error: "Invalid credentials"});
      }
    });
  }
  render() {
    if(this.props.authenticated) {
      const state = {message: "Logged In", type: "alert-success"};

      return <Redirect to={{pathname: "/admin/profile", state}} />;
    }

    const {error, user} = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <h1 className="text-center">Login</h1>
            {error && <Alert className="alert-danger" message={error} onClose={this.clearError} />}
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="session-email">Email</label>
                <input className="form-control" id="session-email" name="email" onChange={this.onChange} placeholder="Email" type="email" value={user.email || ""} />
              </div>
              <div className="form-group">
                <label htmlFor="session-password">Password</label>
                <input className="form-control" id="session-password" name="password" onChange={this.onChange} placeholder="Password" type="password" value={user.password || ""} />
              </div>
              <div className="form-group text-center">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
