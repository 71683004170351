import PropTypes from "prop-types";
import {Component} from "react";
import {Alert} from "components/helpers";

export default class Form extends Component {
  static defaultProps = {message: null, messageType: null}
  static propTypes = {
    button: PropTypes.element.isRequired,
    clearMessage: PropTypes.func.isRequired,
    message: PropTypes.string,
    messageType: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.func.isRequired
  }
  render() {
    const {
      button,
      clearMessage,
      message,
      messageType,
      onChange,
      onSubmit,
      value
    } = this.props;

    return (
      <form onSubmit={onSubmit}>
        {message && (
          <Alert className={messageType} message={message} onClose={clearMessage} />
        )}
        <div className="form-row">
          <div className="col">
            <input className="form-control" name="rank" onChange={onChange} placeholder="Rank" type="number" value={value("rank")} />
          </div>
          <div className="col">
            <input className="form-control" name="name" onChange={onChange} placeholder="Name" type="text" value={value("name")} />
          </div>
          <div className="col">
            <input className="form-control" name="url" onChange={onChange} placeholder="URL" type="url" value={value("url")} />
          </div>
          {button}
        </div>
      </form>
    );
  }
}
