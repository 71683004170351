import {combineReducers, createStore} from "redux";

import profile from "./stores/profile";
import projects from "./stores/projects";
import session from "./stores/session";

const rootReducer = combineReducers({
  profile,
  projects,
  session
});

export default createStore(rootReducer);
