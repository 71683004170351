import PropTypes from "prop-types";
import {Component} from "react";

export default class Alert extends Component {
  static defaultProps = {
    className: "alert-primary",
    onClose: null
  }
  static propTypes = {
    className: PropTypes.string,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func
  }
  constructor(props) {
    super(props);

    this.state = {show: true};
  }
  onClose = (e) => {
    e.preventDefault();

    this.props.onClose
      ? this.props.onClose()
      : this.setState({show: false});
  }
  render() {
    if(!this.state.show) { return null; }

    const {className, message} = this.props;

    return (
      <div className={`alert ${className} alert-dismissible`} role="alert">
        {message}
        <button aria-label="Close" className="close" onClick={this.onClose} type="button">
          <i className="fas fa-times" />
        </button>
      </div>
    );
  }
}
