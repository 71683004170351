export default function Header() {
  return (
    <div className="text-center">
      <h3 className="mt-4 mb-1 text-primary">Tom Prats</h3>
      <div className="mb-2 font-weight-light small text-secondary">Developer Extraordinaire</div>
      <div className="mb-4">
        <a title="Twitter" target="_blank" href="https://twitter.com/tomprats" className="p-2" rel="noopener noreferrer">
          <i className="fab fa-twitter fa-2x" />
        </a>
        <a title="Facebook" target="_blank" href="https://www.facebook.com/tomify.me" className="p-2" rel="noopener noreferrer">
          <i className="fab fa-facebook fa-2x" />
        </a>
        <a title="Github" target="_blank" href="https://github.com/tomprats" className="p-2" rel="noopener noreferrer">
          <i className="fab fa-github fa-2x" />
        </a>
        <a title="LinkedIn" target="_blank" href="https://www.linkedin.com/in/tomprats" className="p-2" rel="noopener noreferrer">
          <i className="fab fa-linkedin fa-2x" />
        </a>
        <a title="Stack Overflow" target="_blank" href="https://stackoverflow.com/users/1197246/tmp" className="p-2" rel="noopener noreferrer">
          <i className="fab fa-stack-overflow fa-2x" />
        </a>
      </div>
    </div>
  );
}
