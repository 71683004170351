import {connect} from "react-redux";
import {updateProfile} from "application/redux/actions/profile";
import {authenticate} from "application/redux/actions/session";
import Component from "./presenter";

const mapStateToProps = ({session: {authenticated}}) => ({authenticated});

export default connect(
  mapStateToProps,
  {authenticate, updateProfile}
)(Component);
