import PropTypes from "prop-types";
import {Component} from "react";
import {update, destroy} from "application/requests/projects";
import Form from "./form";

export default class Show extends Component {
  static propTypes = {
    fetch: PropTypes.func.isRequired,
    project: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      rank: PropTypes.number.isRequired,
      url: PropTypes.string
    }).isRequired
  }
  constructor(props) {
    super(props);

    this.state = {
      changes: {},
      edit: false
    };
  }
  clearMessage = () => {
    this.setState({message: null, messageType: null});
  }
  destroy = () => {
    destroy(this.props.project.id).then((data) => {
      if(data.success) { this.props.fetch(); }
      if(data.message) {
        this.setState({
          message: data.message,
          messageType: `alert-${data.success ? "success" : "danger"}`
        });
      }
    });
  }
  onChange = (e) => {
    const {name, value} = e.target;

    this.setState((prevState) => {
      const changes = {...prevState.changes, [name]: value};

      if(this.props.project[name] === value) { delete changes[name]; }

      return {changes};
    });
  }
  onSubmit = (e) => {
    e.preventDefault();

    update(this.props.project.id, {project: this.state.changes}).then((data) => {
      if(data.success) {
        this.props.fetch();
        this.setState({changes: {}, edit: false});
      }
      if(data.message) {
        this.setState({
          message: data.message,
          messageType: `alert-${data.success ? "success" : "danger"}`
        });
      }
    });
  }
  toggleEdit = () => {
    this.setState((prevState) => ({edit: !prevState.edit}));
  }
  value = (name) => (
    this.state.changes[name] || this.props.project[name] || ""
  )
  render() {
    if(this.state.edit) {
      const button = (
        <div className="btn-group" role="group" aria-label="Actions">
          <button type="submit" className="btn btn-primary">Update</button>
          <button type="button" className="btn btn-secondary" onClick={this.toggleEdit}>Back</button>
        </div>
      );

      const {message, messageType} = this.state;
      const {
        clearMessage,
        onChange,
        onSubmit,
        value
      } = this;

      const props = {
        button,
        clearMessage,
        message,
        messageType,
        onChange,
        onSubmit,
        value
      };

      return (
        <tr>
          <td colSpan="5">
            <Form {...props} />
          </td>
        </tr>
      );
    }

    const {project} = this.props;

    return (
      <tr>
        <td>{project.id}</td>
        <td>{project.rank}</td>
        <td>{project.name}</td>
        <td>{project.url}</td>
        <td>
          <div className="btn-group" role="group" aria-label="Actions">
            <button type="button" className="btn btn-primary" onClick={this.toggleEdit}>Edit</button>
            <button type="button" className="btn btn-danger" onClick={this.destroy}>Destroy</button>
          </div>
        </td>
      </tr>
    );
  }
}
