import PropTypes from "prop-types";
import {Component} from "react";
import {fetchAll as fetchProjects} from "application/requests/projects";
import New from "./new";
import Show from "./show";

export default class Projects extends Component {
  static propTypes = {
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        rank: PropTypes.number.isRequired,
        url: PropTypes.string
      })
    ).isRequired,
    updateProjects: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    this.fetchProjects();
  }
  fetchProjects = () => {
    fetchProjects().then((data) => (this.props.updateProjects(data.projects)));
  }
  render() {
    const {projects} = this.props;

    return (
      <div className="container-fluid">
        <div className="table-responsive ">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>ID</th>
                <th>Rank</th>
                <th>Name</th>
                <th>URL</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <Show key={project.id} fetch={this.fetchProjects} project={project} />
              ))}
              <tr>
                <td colSpan="5">
                  <New fetch={this.fetchProjects} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
