import {Component} from "react";

// Similar to https://react-bootstrap.netlify.com/components/navbar/
export default class Navbar extends Component {
  componentDidMount() {
    window.addEventListener("resize", this.closeMenusOnResize, false);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.closeMenusOnResize, false);
  }
  closeMenusOnResize = () => {
    if(document.body.clientWidth < 768) { return; }

    [...document.querySelectorAll(".navbar-collapse")].forEach((collapse) => {
      collapse.classList.add("collapse");
      collapse.classList.remove("in");
    });
  }
  toggleMenu = (e) => {
    const collapse = e.target.closest("nav").querySelector(".navbar-collapse");

    collapse.classList.toggle("collapse");
    collapse.classList.toggle("in");
  }
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-success">
        <button className="navbar-toggler" type="button" aria-label="Toggle navigation" onClick={this.toggleMenu}>
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0 mx-auto text-center">
            <a className="nav-item nav-link active" href="/">Home</a>
            <a className="nav-item nav-link active" href="/tomify">Tomify</a>
            <a className="nav-item nav-link active" href="https://missions.tomify.me" target="_blank" rel="noopener noreferrer">Missions</a>
            <a className="nav-item nav-link active" href="/contact">Contact</a>
            <a className="nav-item nav-link active" href="/admin">Admin?</a>
          </ul>
        </div>
      </nav>
    );
  }
}
