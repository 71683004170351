export default function Love() {
  return (
    <div className="container-fluid text-center">
      <h1>Made with Love</h1>
      <div className="row">
        <div className="col-sm-4 offset-sm-4">
          <p>
            I'd like to promote making the world into a better place. To do
            so, I try to help those around me. You should too.
          </p>
        </div>
      </div>
    </div>
  );
}
