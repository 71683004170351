import PropTypes from "prop-types";
import {Component} from "react";
import {Redirect} from "react-router-dom";
import {fetchCurrent as fetchProfile} from "application/requests/profile";
import {destroy as destroySession} from "application/requests/session";

// Similar to https://react-bootstrap.netlify.com/components/navbar/
export default class Navbar extends Component {
  static defaultProps = {authenticated: false}
  static propTypes = {
    authenticate: PropTypes.func.isRequired,
    authenticated: PropTypes.bool,
    updateProfile: PropTypes.func.isRequired
  }
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    fetchProfile().then((data) => {
      this.props.authenticate(data.user != null);
      this.props.updateProfile(data.user);
    });
  }
  destroySession = () => {
    destroySession().then((data) => {
      this.props.authenticate(data.success);
      if(data.success) { this.setState({unauthenticated: true}); }
    });
  }
  toggleMenu = (e) => {
    const collapse = e.target.closest("nav").querySelector(".navbar-collapse");

    collapse.classList.toggle("collapse");
    collapse.classList.toggle("in");
  }
  render() {
    if(this.state.unauthenticated) {
      const state = {message: "Logged Out", type: "alert-danger"};

      return <Redirect to={{pathname: "/", state}} />;
    }

    const {authenticated} = this.props;

    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <button className="navbar-toggler" type="button" aria-label="Toggle navigation" onClick={this.toggleMenu}>
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0 mx-auto text-center">
            {authenticated ? [
              <a key="profile" className="nav-item nav-link active" href="/admin/profile">Profile</a>,
              <a key="projects" className="nav-item nav-link active" href="/admin/projects">Projects</a>,
              <button key="logout" className="nav-item nav-link active btn btn-link" onClick={this.destroySession} type="button">Logout</button>
            ] : (
              <a className="nav-item nav-link active" href="/admin/sessions">Login</a>
            )}
          </ul>
        </div>
      </nav>
    );
  }
}
