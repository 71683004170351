import Rails from "@rails/ujs";
import {render} from "react-dom";
import {Provider} from "react-redux";
import "whatwg-fetch";
import store from "application/redux/store";
import Router from "components/router";
import "application/style.scss";

Rails.start();

document.addEventListener("DOMContentLoaded", () => {
  render(
    <Provider store={store}>
      <Router />
    </Provider>,
    document.getElementById("app")
  );
});
