import {
  AUTHENTICATE
} from "application/redux/actions/session";

export default (
  state = {},
  action
) => {
  switch(action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        authenticated: action.authenticated
      };
    default:
      return state;
  }
};
