export default function Contact() {
  return (
    <div className="container-fluid text-center">
      <h1>Contact</h1>
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <p>
            Email or message me anytime and I'll do my best to get back to
            you within 24 hours.
          </p>
        </div>
      </div>
      <p>
        <a target="_blank" href="mailto:tom@tomify.me" rel="noopener noreferrer">
          <i className="fas fa-paper-plane mr-1" />
          tom@tomify.me
        </a>
      </p>
      <p>
        <a target="_blank" href="https://www.facebook.com/tomify.me" rel="noopener noreferrer">
          <i className="fab fa-facebook-f mr-1" />
          tomify.me
        </a>
      </p>
      <p>
        <a target="_blank" href="https://www.twitter.com/tomprats" rel="noopener noreferrer">
          <i className="fab fa-twitter mr-1" />
          tomprats
        </a>
      </p>
    </div>
  );
}
