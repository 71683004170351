import PropTypes from "prop-types";
import {Component} from "react";
import {fetchAll as fetchProjects} from "application/requests/projects";

export default class Tomify extends Component {
  static propTypes = {
    projects: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string
      })
    ).isRequired,
    updateProjects: PropTypes.func.isRequired
  }
  componentDidMount() {
    if(this.props.projects.length > 0) { return; }

    fetchProjects().then((data) => (this.props.updateProjects(data.projects)));
  }
  render() {
    let {projects} = this.props;
    if(projects.length === 0) {
      projects = [
        {name: "Websites"},
        {name: "iOS/Android Apps"},
        {name: "Desktop Applications"}
      ];
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <h1>Projects</h1>
            <p>
              Tomify can build anything, but we specialize in websites. We
              can work with technology like Paypal or Stripe to allow your
              customers to pay online, as well as Facebook, Instagram,
              Twitter or Youtube to display and interact with your content.
              Here are some examples of what we can do:
            </p>
            <ul>
              {projects.map((project) => (
                <li key={project.name}>
                  {project.url ? (
                    <a href={project.url}>{project.name}</a>
                  ) : (
                    <span>{project.name}</span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-4">
            <h1>Who Are We?</h1>
            <p>We are an extension of Tom Prats.</p>
            <p>
              Have a project that's bigger than just a one man job? Tomify is
              about getting the correct and qualified resources to do what
              you need done. From design work to mobile apps, we do it all.
            </p>
          </div>
          <div className="col-md-4">
            <h1>Want More?</h1>
            <p>
              Tomify is about designing the world of technology exactly how
              you want it. It's what we do. Ask us to do something, even if
              it's not a website, if we don't know how to do it, then
              we'll learn.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
