import {
  UPDATE_PROJECTS
} from "application/redux/actions/projects";

export default (
  state = [],
  action
) => {
  switch(action.type) {
    case UPDATE_PROJECTS:
      return [...action.projects];
    default:
      return state;
  }
};
