import PropTypes from "prop-types";
import {Component} from "react";
import {Alert} from "components/helpers";

export default class Message extends Component {
  static propTypes = {
    location: PropTypes.shape({state: PropTypes.object}).isRequired
  }
  render() {
    const params = this.props.location.state || {};

    if(!params.message) { return null; }

    const props = {message: params.message};
    if(params.type) { props.className = params.type; }

    return <Alert {...props} />;
  }
}
