import PropTypes from "prop-types";
import {Component} from "react";
import {create} from "application/requests/projects";
import Form from "./form";

export default class New extends Component {
  static propTypes = {fetch: PropTypes.func.isRequired}
  constructor(props) {
    super(props);

    this.state = {project: {}};
  }
  clearMessage = () => {
    this.setState({message: null, messageType: null});
  }
  onChange = (e) => {
    const {name, value} = e.target;

    this.setState((prevState) => (
      {project: {...prevState.project, [name]: value}}
    ));
  }
  onSubmit = (e) => {
    e.preventDefault();

    create({project: this.state.project}).then((data) => {
      if(data.success) {
        this.props.fetch();
        this.setState({project: {}});
      }
      if(data.message) {
        this.setState({
          message: data.message,
          messageType: `alert-${data.success ? "success" : "danger"}`
        });
      }
    });
  }
  value = (name) => (
    this.state.project[name] || ""
  )
  render() {
    const button = <button type="submit" className="btn btn-primary">Create</button>;
    const {message, messageType} = this.state;
    const {
      clearMessage,
      onChange,
      onSubmit,
      value
    } = this;

    const props = {
      button,
      clearMessage,
      message,
      messageType,
      onChange,
      onSubmit,
      value
    };

    return <Form {...props} />;
  }
}
